const isUndefined = (value) => value === undefined

const evaluateGuards = (guards, to, from, next) => {
  // Using spread operator for array clone
  const guardsLeft = [...guards]
  const nextGuard = guardsLeft.shift()

  if (isUndefined(nextGuard)) {
    next()
    return
  }

  nextGuard(to, from, (nextArg) => {
    if (isUndefined(nextArg)) {
      evaluateGuards(guardsLeft, to, from, next)
      return
    }

    next(nextArg)
  })
}

export default (guards) => {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards')
  }

  return (to, from, next) => evaluateGuards(guards, to, from, next)
}
