export const isBlackWeekActive = () => {
  const currentDate = new Date()
  const startDate = new Date(Date.UTC(2024, 10, 25, 0, 0, 0))
  const endDate = new Date(Date.UTC(2024, 11, 2, 23, 59, 59))

  const currentUTCDate = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate(),
    currentDate.getUTCHours(),
    currentDate.getUTCMinutes(),
    currentDate.getUTCSeconds()
  )

  return currentUTCDate >= startDate && currentUTCDate <= endDate
}
